import React from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "locales";
import { Router, RouterProps } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import Notification from "pages/shareableComponents/Notification";
import store from "store";
import theme from "theme";
import { axiosClient, axiosReportClient, directoryClient } from "api/web";
import { injectRequestInterceptor, injectResponseRejectedInterceptor } from "api/utils";
import {
  directoryAuthCheckInterceptor,
  authCheckInterceptor,
  cancelPrevDuplicatedRequestInterceptor,
} from "api/interceptors";

[directoryClient].forEach((client) => {
  injectResponseRejectedInterceptor(client, directoryAuthCheckInterceptor);
});
[axiosReportClient, axiosClient].forEach((client) => {
  injectResponseRejectedInterceptor(client, authCheckInterceptor);
});
[axiosReportClient].forEach((client) => {
  injectRequestInterceptor(client, cancelPrevDuplicatedRequestInterceptor);
});

interface ProvidersProps {
  history: RouterProps["history"];
}

const Providers: React.FC<ProvidersProps> = ({ history, children }) => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <I18nextProvider i18n={i18n}>
            <SnackbarProvider maxSnack={3}>
              {children}
              <Notification />
            </SnackbarProvider>
          </I18nextProvider>
        </ThemeProvider>
      </Router>
    </Provider>
  );
};

export default Providers;
