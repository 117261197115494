import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { isUserAdmin } from "../utils/user";
import { VM_LIST, VM_CREATE, VM_UPDATE, DISKS, VNC, REPORTS, PROTECTED_PATHS, PUBLIC_PATHS, routeMap } from "./routes";
import { hasUserAccessToFeature, FEATURES, USER_ACCESS } from "@skyportal/auth-web-client";
import ScrollToTop from "./ScrollToTop";

const MainRouter = ({ profile }) => {
  return (
    <ScrollToTop>
      <Switch>
        {PUBLIC_PATHS.map((path) => (
          <Route key={path} path={path} {...routeMap[path]} />
        ))}
        {hasUserAccessToFeature(profile, FEATURES.IAAS_VIRTUAL_MACHINES, [USER_ACCESS.VIEW]) && (
          <Route path={VM_LIST} {...routeMap[VM_LIST]} />
        )}
        {hasUserAccessToFeature(profile, FEATURES.IAAS_VIRTUAL_MACHINES, [USER_ACCESS.CREATE]) && (
          <Route path={VM_CREATE} {...routeMap[VM_CREATE]} />
        )}
        {hasUserAccessToFeature(profile, FEATURES.IAAS_VIRTUAL_MACHINES, [USER_ACCESS.UPDATE]) && (
          <Route path={VM_UPDATE} {...routeMap[VM_UPDATE]} />
        )}
        {hasUserAccessToFeature(profile, FEATURES.IAAS_DISKS, [USER_ACCESS.VIEW]) && (
          <Route path={DISKS} {...routeMap[DISKS]} />
        )}
        {hasUserAccessToFeature(profile, FEATURES.IAAS_VNC_CONSOLE, [USER_ACCESS.VIEW]) && (
          <Route path={VNC} {...routeMap[VNC]} />
        )}
        {(hasUserAccessToFeature(profile, FEATURES.IAAS_USAGE_REPORT, [USER_ACCESS.VIEW]) ||
          hasUserAccessToFeature(profile, FEATURES.IAAS_USAGE_REPORT_DETAILED, [USER_ACCESS.VIEW])) && (
          <Route path={REPORTS} {...routeMap[REPORTS]} />
        )}
        {isUserAdmin(profile) && PROTECTED_PATHS.map((path) => <Route key={path} path={path} {...routeMap[path]} />)}
        <Route path="*">
          <Redirect to="/infra" />
        </Route>
      </Switch>
    </ScrollToTop>
  );
};

export default React.memo(MainRouter);
