/**
 * @typedef {object} BreadCrumbsItem
 * @property {string} - label
 * @property {string} - path
 * @property {string} - [faIcon]
 * @property {string} - [dustinIcon]
 */

/**
 * @typedef {object} BreadCrumbsConfigItem
 * @property {string} - label
 * @property {string} - path
 * @property {BreadCrumbsItem[]} - [crumbs]
 * @property {object} - match
 * @property {string} - match.path
 * @property {boolean} - match.exact
 * @property {string} - [faIcon]
 * @property {string} - [dustinIcon]
 */

const Infrastructure = {
  label: "Private Cloud",
  path: "/infra",
  dustinIcon: "cloud-safe",
};

const Vm = {
  label: "VMs",
  path: "/infra/vm",
};

/**
 * @type {BreadCrumbsConfigItem[]}
 */
export default [
  {
    label: Infrastructure.label,
    dustinIcon: Infrastructure.dustinIcon,
    match: {
      path: Infrastructure.path,
      exact: true,
    },
  },
  {
    label: Vm.label,
    crumbs: [Infrastructure],
    dustinIcon: "stationary-box",
    match: {
      path: Vm.path,
      exact: true,
    },
  },
  {
    label: "Create VM",
    crumbs: [Infrastructure, Vm],
    match: {
      path: "/infra/vm/create",
    },
  },
  {
    label: "Edit VM",
    crumbs: [Infrastructure, Vm],
    match: {
      path: "/infra/vm/edit/:id",
    },
  },
  {
    label: "Vnc",
    crumbs: [Infrastructure, Vm],
    match: {
      path: "/infra/vm/vnc/:id",
    },
  },
  {
    label: "Regions",
    crumbs: [Infrastructure],
    dustinIcon: "market",
    match: {
      path: "/infra/region",
    },
  },
  {
    label: "Datacenters",
    crumbs: [Infrastructure],
    dustinIcon: "office",
    match: {
      path: "/infra/datacenter",
    },
  },
  {
    label: "Disks",
    crumbs: [Infrastructure],
    dustinIcon: "doc",
    match: {
      path: "/infra/disks",
    },
  },
  {
    label: "Event History",
    crumbs: [Infrastructure],
    dustinIcon: "clock",
    match: {
      path: "/infra/event-history",
    },
  },
  {
    label: "Usage Report",
    crumbs: [Infrastructure],
    dustinIcon: "statistic",
    match: {
      path: "/infra/reports",
    },
  },
];
