import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import BreadCrumbs from "../../components/BreadCrumbs";

import styles from "./styles";

const useStyles = makeStyles(styles);

const PageHeader = ({ title = "", backPath, children = null }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.header} data-testid="PageHeader">
      <div className={classes.titleWrapper}>
        {backPath && (
          <Link to={backPath} title={t("Back")}>
            <Button leftIcon="arrow-left" />
          </Link>
        )}
        <h1 className={classes.headerText}>{title}</h1>
      </div>
      <div className={classes.content}>{children}</div>
      <BreadCrumbs />
    </div>
  );
};

export default PageHeader;
