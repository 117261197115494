const styles = (theme) => ({
  root: {
    display: "flex",
    gap: "8px",
    "@media(max-width: 767px) ": {
      width: "100%",
      padding: "8px",
      backgroundColor: "#d2d6de",
      margin: "8px 0",
      fontSize: "14px",
      borderRadius: "4px",
    },
  },
  breadcrumbs: {
    display: "flex",
    alignItems: "center",
    fontSize: theme.fonts.small,
    color: "black",
    textTransform: "capitalize",
  },
  breadcrumbLink: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "black",
  },
  base64LinkIcon: {
    width: "12px",
    height: "12px",
    marginRight: "4px",
  },
});

export default styles;
